import {confirmOtp as confirmOtpApi, sendOtpV2 as sendOtpApi} from "data/otp";
import { confirmLogout as confirmLogoutApi } from "data/logout";
import {COOKIES, setCookie} from "utils/cookies";
import {getState, redirect} from "utils/misc";
import {NextRouter} from "next/router";
import { toast } from "react-toastify";
import {logoutStaff} from "../data/saas";
import {ADMIN_SESSION_EXPIRY_TIME} from "../constants/expiry";

export const handleSendOTP = async (phoneNumber: string, setAuthToken: Function, setPageIndex: Function, startResendCount: Function, setIsLoading: Function) => {
  try {
    if (phoneNumber.length === 10) {
      // call otp api
      setIsLoading(true);
      const sendOtpResponse = await sendOtpApi(phoneNumber);
      setIsLoading(false);
      setAuthToken(sendOtpResponse.authToken);
      setPageIndex(1);
      startResendCount();
    }
  } catch (error) {
    setIsLoading(false);
    const e = error.response? error.response.data.status :"Something went wrong";
    toast.error(e);
  }
}

export const handleVerifyOTP = async (OTP: string, authToken: string, router: NextRouter, setIsLoading: Function) => {
  if (OTP.length === 4) {
    try {
      const utmSource = new URLSearchParams(window.location.search).get('utm_source') || '';
      // call otp api
      setIsLoading(true);
      const {data:verificationResponse, headers} = await confirmOtpApi(authToken, OTP, utmSource);
      const xSessionId=headers[COOKIES.xSessionId];
      if (verificationResponse.authToken && xSessionId) {
        delete verificationResponse.welcomeVideoLanguage;
        setCookie(null, COOKIES.recSession, JSON.stringify(verificationResponse), null, 30);
        setCookie(null, COOKIES.xSessionId, xSessionId, null, ADMIN_SESSION_EXPIRY_TIME  );
        const {code, shop, state, hmac, client_id} = getState(router);
        if (code && shop && state && hmac) {
          redirect({
            pathname: '/link-shopify',
            query: getState(router),
          });
        } else if (client_id) {
          redirect({
            pathname: '/freshdesk/login',
            query: getState(router),
          });
        } else {
          redirect({
            pathname: getState(router).redirect_uri ? decodeURIComponent(getState(router).redirect_uri) : '/',
          });
        }
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(e.response?.data?.status || "Something went wrong");
    }
  }
}

export const sessionLogout = async (onSuccess?: Function) => {
  try {
    await confirmLogoutApi();
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};

export const staffSessionLogout = async (onSuccess: Function, email: string) => {
  try {
    await logoutStaff(email);
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};
